@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.headline-container {
    max-width: 525px;
    padding-bottom: 6.4px;

    @include screen-min-width(#{$medium-plus}) {
        padding-bottom: 8px;
    }

    &.standard {
        margin-bottom: 8px;
        padding-bottom: 0;
        @include screen-min-width(#{$medium-standard}) {
            max-width: 560px;
            width: 100%;
        }
    }

    &.big-idea {
        margin-bottom: 9px;
    }

    &.big-idea,
    &.spotlight,
    &.premium {
        margin: 0 auto;

        @include screen-min-width(#{$x-large-plus}) {
            max-width: 610px;
            width: 100%;
        }
    }
}

.title {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.1664px;
    line-height: 1;
    margin-bottom: 0;
    max-width: 610px;

    &.standard {
        letter-spacing: -0.167px;
        line-height: 27px;
        width: 75%;

        @include screen-min-width(#{$medium-standard}) {
            font-size: 44px;
            line-height: 46px;
            width: 100%;
        }
    }

    &.premium {
        font-family: $font-stack-tiempos;
        letter-spacing: -0.167px;
        line-height: 27px;

        @include screen-min-width(#{$medium-plus}) {
            font-family: $font-stack-sans-serif;
            line-height: 1;
        }
    }

    &.big-idea {
        font-family: "Tiempos Headline";
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.25px;
        margin: 0 auto;
        text-align: center;
        width: 100%;

        @include screen-min-width(#{$medium-standard}) {
            font-size: 40px;
            letter-spacing: -0.01px;
            line-height: 1.15;
        }
    }

    @include screen-min-width(#{$medium-plus}) {
        font-size: 44px;
    }

    &.spotlight {
        letter-spacing: -0.25px;
        margin: 0 auto;
        text-align: center;
        width: 100%;

        @include screen-min-width(#{$x-large-plus}) {
            letter-spacing: normal;
            max-width: 560px;
            width: 100%;
        }
    }
}
