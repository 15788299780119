@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    margin-bottom: 32px;
    padding-top: 12px;

    @include screen-min-width($medium-plus) {
        padding-left: 24px;
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            padding-left: 24px;
        }
    }

    &.bio-in-content {
        display: block;

        @include screen-min-width($medium-plus) {
            display: none;
        }
    }
}

.no-authors {
    margin: 0;
    min-height: 32px;
    padding: 0;
}
