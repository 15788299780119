@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";

.inline-caption {
    color: $color-iron;
    font-family: $font-stack-sans-serif;
    font-size: 10px;
    line-height: 1.5;
    margin-right: 7px;
}
