/* stylelint-disable value-no-vendor-prefix, max-nesting-depth */
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.sidebar-contents {
    padding-right: 12px;
    width: 100%;

    &.collapsed {
        margin-bottom: 12px;
        padding-right: 12px;
    }

    div {
        color: #666;
        font-size: 17px;
        font-weight: 200;
        line-height: 22.4px;
    }

    // We need to create a SpanDefault component
    p > span {
        color: $color-iron;
        font-family: $font-stack-sans-serif;
        font-size: 16px;
        font-weight: 200;
        line-height: 1.6875;
    }

    @include screen-min-width($medium-standard) {
        max-width: 520px;
        width: 100%;
    }

    table {
        td {
            font-size: 11px;
            font-weight: 200;
            line-height: 1.273;
            padding: 16px 14px;
        }
    }

    tbody {
        strong {
            font-weight: 700;
        }
    }

    tr:nth-child(1) {
        strong {
            font-size: 11px;
            line-height: 1.273;
            text-align: center;
        }
    }

    strong {
        font-weight: 700;
    }
}

.thumbnail {
    img {
        display: block;
        float: left;
        height: auto;
        max-width: 35%;
        padding: 0 16px 16px 0;
        width: 100%;

        @include screen-min-width($medium-standard) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.article-sidebar-expandable {
    &.collapsed {
        table {
            display: none;
        }
    }

    @include screen-min-width($medium-standard) {
        width: 240px;

        &.expanded {
            max-width: 520px;
            width: 100%;

            table {
                display: table;
            }
        }
    }
}

.sidebar-full {
    margin-left: -5px;
    max-width: 100%;
    padding-right: 0;

    @include screen-min-width(1025px) {
        margin-left: 25px;
    }
}

.ellipsis {
    display: inline;

    @include screen-min-width($medium-standard) {
        display: block;
        padding-bottom: 12px;
    }
}

.truncated-text {
    color: $color-iron;
    font-family: $font-stack-sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.6875;
}
