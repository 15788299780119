.wrapper {
    margin-bottom: 16px;
}

.youtube {
    overflow: hidden;
    padding-bottom: 67.5%;
    padding-top: 25px;
    position: relative;
}
