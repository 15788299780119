@import "~mfe-articles-shared/scss/mixins/media-queries";

.promo-right {
    &.standard {
        @include screen-min-width(1250px) {
            position: absolute;
        }
    }

    &:not(.standard) {
        display: none;
    }
}
