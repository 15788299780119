@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";

.headline-container-header {
    display: none;
    font-family: $font-stack-tiempos-headline;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    @include screen-min-width($large-plus) {
        display: block;
        font-family: $font-stack-lava;
    }
}

.headline-container {
    display: block;
    font-family: $font-stack-tiempos-headline;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 16px;
    margin-top: 32px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;

    @include screen-min-width($medium-plus) {
        display: none;
        font-family: $font-stack-lava;
    }
}

.headline-title-anchor {
    cursor: pointer;
    font-family: $font-stack-tiempos-headline;
    letter-spacing: -0.167px;

    @include screen-min-width($medium-plus) {
        font-family: $font-stack-lava;
    }
}

.headline-separator {
    margin-left: 4px;
    margin-right: 4px;

    @include screen-min-width($medium-plus) {
        margin-left: 6px;
        margin-right: 6px;
    }
}

.headline-series {
    color: #fe5502;
    letter-spacing: -0.167px;

    @include screen-min-width($medium-plus) {
        letter-spacing: -0.75px;
    }
}
