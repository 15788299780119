@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

@mixin common-utility-list() {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.container-top {
    display: none;

    @include screen-min-width($medium-standard) {
        @include common-utility-list();

        border-bottom: 1px solid $legacy-color-border-gray;
        margin-bottom: 48px;
        margin-top: 16px;
    }

    @include screen-min-width($x-large-standard) {
        display: none;
    }
}

.container-left {
    display: none;

    &:not(.standard) {
        @include screen-min-width($medium-plus) {
            border-right: 1px solid $legacy-color-border-gray;
            display: block;
            flex-direction: column;
            width: 110px;

            @include common-utility-list();
        }
    }

    &.standard {
        @include screen-min-width($x-large-standard) {
            border-right: 1px solid $legacy-color-border-gray;
            display: block;
            flex-direction: column;
            width: 105px;

            @include common-utility-list();
        }
    }
}

.container-bottom {
    @include common-utility-list();

    border-top: 1px solid $legacy-color-border-gray;
    padding: 16px 8px 24px;

    @include screen-min-width($medium-standard) {
        padding: 16px 30px 24px;
    }
}

.list-item {
    list-style-type: none;
    text-align: center;
}

.list-item.vertical {
    margin-bottom: 24px;
    margin-top: 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.hidden-horizontal-utility {
    display: none;
    @include screen-min-width($medium-standard) {
        display: block;
    }

    &.premium {
        display: none;

        @include screen-min-width($medium-plus) {
            display: block;
        }
    }
}

.utility-name {
    display: none;
    line-height: 1;

    @include screen-min-width($medium-standard) {
        display: block;
        padding-top: 8px;
    }

    &.premium {
        display: none;
        line-height: 1;

        @include screen-min-width($medium-plus) {
            display: block;
            padding-top: 8px;
        }
    }
}

.item-button {
    background-color: $color-white;
    border-style: none;
    color: inherit;
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }

    img {
        display: block;
        margin: 0 auto;
    }
}
