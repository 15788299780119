@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.article-sidebar {
    border-bottom: 1px $legacy-color-border-gray solid;
    border-top: 1px #e2e2e2 solid;
    box-sizing: border-box;
    font-family: $font-stack-sans-serif;
    margin: 9px 0 48px;
    padding: 32px 0;
    width: 80%;

    &.collapsed {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @include screen-min-width($medium-standard) {
        max-width: 600px;
        width: 100%;

        &.sidebar-right,
        &.sidebar-half-right {
            float: right;
            margin-left: 56px;
            max-width: 240px;
        }

        &.sidebar-half-right {
            border-bottom: 1px $legacy-color-border-gray solid;
            display: block;
            margin-bottom: 48px;
            margin-left: 56px;
            max-width: 240px;
            padding-bottom: 32px;
            width: 80%;
        }

        &.sidebar-half {
            clear: left;
            float: left;
            margin-right: 56px;
            max-width: 240px;
        }

        &.sidebar-wide-right {
            width: 100%;
        }

        &.article-sidebar-expandable {
            border-bottom: 0;
            max-width: 100%;
            width: auto;

            &.expanded {
                border-bottom: 1px $legacy-color-border-gray solid;
                float: none !important;
                margin: 0 0 48px;
                max-width: 610px !important;
                width: 100%;
            }
        }
    }
}

.button-expand {
    background-color: #c3dddb;
    border-color: transparent;
    border-radius: 16px;
    clear: both;
    cursor: pointer;
    display: block;
    height: 32px;
    margin-bottom: 48px;
    margin-top: 40px;
    padding: 0;
    text-align: center;
    width: 32px;

    @include screen-min-width($medium-plus) {
        margin-top: 16px;
    }
}

.icon-expand {
    color: #111;
    font-family: $font-hbr-icons;
    font-size: 21px;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    &::before {
        content: "\e090";
    }
}

.sidebar-collapse {
    background: 0 0;
    border: 1px #c3dddb solid;
    border-radius: 16px;
    cursor: pointer;
    float: right;
    height: 32px;
    margin-top: -20px;
    text-align: center;
    width: 32px;
}

.icon-collapse {
    font-family: $font-hbr-icons;
    font-size: 18px;
    font-style: unset;
    line-height: 1.75;

    &::before {
        content: "\e012";
    }
}

.sidebar-full {
    border-bottom: 0;

    @include screen-min-width($medium-plus) {
        max-width: calc(100vw - 30px);
    }

    &.big-idea {
        margin-left: 30px;

        @include screen-min-width($medium-plus) {
            margin-left: calc((585px - 100vw) / 2);
            max-width: calc(100vw - 20px);
            width: calc(100vw - 20px);
        }
    }
}
