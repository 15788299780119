@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/grid-layout";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.item {
    border-bottom: 1px $legacy-color-border-gray solid;
    color: $color-charcoal;
    display: flex;
    flex-direction: row;
    font-family: $font-stack-sans-serif;
    font-weight: 700;
    margin-bottom: -1px;
    padding-bottom: 24px;
    padding-top: 24px;
    text-decoration: none;

    @include screen-min-width($article-width) {
        border: 0;
        flex-direction: column;
        padding: 24px 24px 24px 13px;
        scroll-snap-align: start;
    }

    &.selected {
        background-color: rgba(195, 221, 219, 0.3);
    }

    &:hover > .item-title,
    &:hover > .item-image {
        opacity: 0.6;
    }
}

.item.top {
    @include screen-min-width($article-width) {
        max-width: 200px;
        min-width: 33%;
    }

    @include screen-min-width($medium-plus) {
        max-width: 200px;
        min-width: 25%;
    }

    @include screen-min-width($x-large-plus) {
        min-width: 152.5px;
    }

    &:first-child {
        margin-left: auto;
    }

    &:last-child {
        margin-right: auto;
    }
}

.item-index {
    color: $color-charcoal;
    font-size: 13px;
    font-weight: 700;
    opacity: 0.35;
    padding: 0 16px;

    @include screen-min-width($article-width) {
        margin-bottom: 6px;
        padding: 0;
    }
}

.item-summary {
    padding: 16px;
}

.item-image {
    display: block;
    height: 63px;
    margin-bottom: 12px;
    width: 112px;

    @include screen-min-width($article-width) {
        height: auto;
        width: 100%;
    }
}

.item-thumbnail {
    height: auto;
    object-fit: cover;
    vertical-align: middle;
    width: 100%;
}

.item-title {
    font-family: $font-stack-sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;

    @include screen-min-width($article-width) {
        font-weight: 200;
    }
}
