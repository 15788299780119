@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.sidebar-title {
    color: $color-iron;
    font-family: $font-stack-tiempos;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 30px;
    margin-bottom: 0;
    padding-bottom: 24px;
    width: 100%;

    @include screen-min-width($medium-standard) {
        font-size: 24px;
        line-height: 25px;

        &.article-sidebar-expandable {
            width: 240px;

            &:not(.expanded) {
                font-size: 19px;
            }

            &.expanded {
                font-size: 24px;
                width: 525px;
            }
        }
    }
}

.sidebar-half {
    @include screen-min-width($medium-standard) {
        font-size: 19px;
    }
}
