@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.lead-in {
    font-family: $font-stack-sans-serif;
    font-size: 17px;
    font-weight: 700;

    @include screen-min-width($medium-plus) {
        font-size: 19px;
    }
}

.lead-in-large {
    font-family: $font-stack-sans-serif;
    font-size: 19px;

    @include screen-min-width($medium-plus) {
        font-weight: 700;
    }
}
