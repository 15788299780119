@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.wrapper {
    padding: 0 20px;

    @include screen-min-width($medium-standard) {
        margin-top: 38px;
        padding: 0;
    }
}
