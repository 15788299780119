@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    display: none;

    @include screen-min-width($large-standard) {
        border-left: 1px solid $legacy-color-border-gray;
        display: block;
        left: calc(100vw - 340px);
        margin-top: 9px;
        padding-left: 16px;
        position: absolute;
        width: 270px;
    }

    @include screen-min-width(1250px) {
        left: 738px;
    }
}

.link {
    color: #000 !important;
    font-family: $font-stack-sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 32.632px;
    text-decoration: none;
}

.header {
    color: $color-iron;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.214;
    padding-bottom: 5px;
}

.title {
    color: $color-charcoal;
    font-size: 20px;
    margin-bottom: 13px;
    margin-top: 0;
}

.dek {
    font-size: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.357;
}
