@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.print-logo {
    display: none;
    margin-bottom: -10px;
    margin-left: 12%;
    width: 60px;

    &.big-idea {
        margin-bottom: 24px;
    }

    @media print {
        display: block;
    }

}
