@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";

.container {
    font-family: $font-stack-sans-serif;
}

.embed-video-hide--half {
    @media only screen and (min-width: $medium-standard), print {
        display: inherit !important;
    }
}

.embed-video-hide--full {
    @media only screen and (min-width: $medium-standard), print {
        display: none !important;
    }

    @media screen and (max-width: $medium-standard) {
        display: none !important;
    }
}

.sidebar--half {
    clear: left;
    float: left;
    margin-right: 3.99%;
    width: 46.015%;

    @media screen and (max-width: $medium-standard) {
        .embed-video-hide--full {
            display: inherit !important;
        }

        .embed-video-hide--half {
            display: none !important;
        }
    }
}
