@import "~mfe-articles-shared/scss/variables/fonts";

.table-footer {
    align-items: flex-end;
    color: #666;
    display: flex;
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    margin-bottom: -16px;
    margin-top: -4px;
    min-height: 23px;
    position: relative;

    &::after {
        background: url("../../assets/HBR-logo-tables.svg");
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0;
        content: "";
        display: block;
        height: 23px;
        position: absolute;
        right: 0;
        width: 20px;
    }
}
