@import "~mfe-articles-shared/scss/variables/colors";

.link {
    border-color: $color-hbr-red;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: $color-hbr-red !important;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-left: 0;
    margin-top: 10px;
    padding: 11px 16px;
    position: relative;
    text-align: center;

    &:hover {
        border-color: $legacy-color-maroon;
        color: $legacy-color-maroon !important;
    }
}
