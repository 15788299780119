@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.byline {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    font-size: 15px;
    letter-spacing: -0.3px;
    line-height: 21px;

    @include screen-min-width($medium-standard) {
        &.big-idea {
            font-size: 18px;
        }

        &:not(.big-idea) {
            font-size: 20px;
        }
    }

    .author {
        color: $color-charcoal;
        letter-spacing: -0.167px;
        line-height: 21px;
        text-decoration: none;
    }

    a:hover {
        opacity: 0.6;
    }
}
