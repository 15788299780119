.dek-byline-container {
    line-height: 22.4px;
    max-width: 610px;

    &.standard {
        line-height: 1;
        text-align: left;
    }

    &.big-idea,
    &.spotlight,
    &.premium {
        text-align: center;
    }
}

.dek-byline-content {
    margin-top: 0;

    &.big-idea {
        margin-top: 9px;
    }
}
