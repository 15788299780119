@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.container {
    &:not(.standard) {
        padding: 0 40px;

        @include screen-min-width($medium-standard) {
            padding: 0 20px;
        }
    }

    @media print {
        display: none;
    }
}

.right-rail {
    margin-top: 0;
    padding-left: 0;

    div {
        color: $color-iron;
        font-family: $font-stack-sans-serif !important;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        margin-bottom: 4px;
    }

    a {
        font-family: $font-stack-sans-serif !important;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 17px;
    }

    img {
        height: 90px !important;
        object-fit: cover;
        width: 90px !important;
    }
}
