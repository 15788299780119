@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    border-bottom: 1px $legacy-color-border-gray solid;
    border-top: 1px #e2e2e2 solid;
    display: flex;
    flex-wrap: nowrap;
    font-family: $font-stack-sans-serif;
    margin-bottom: 48px;
    margin-right: 22px;
    margin-top: 48px;
    padding-bottom: 24px;
    padding-top: 24px;

    @include screen-min-width($medium-standard) {
        flex-wrap: wrap;
        margin-right: 0;
        padding-bottom: 32px;
        padding-top: 32px;
    }
}

.icon {
    margin-right: 16px;

    @include screen-min-width($medium-standard) {
        margin-left: 24px;
        margin-right: 24px;
    }
}

.logo {
    height: 50px;
    width: 50px;
}

.content {
    @include screen-min-width($medium-standard) {
        flex: 0 0 80%;
    }
}

.description-wrapper {
    width: 247px;

    @include screen-min-width($medium-standard) {
        margin-right: 80px;
        width: auto;
    }
}

.podcast-header {
    box-sizing: border-box;
    color: $color-iron;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.01px;
    line-height: 19.2px;
    margin-bottom: 4px;
}

.podcast-name {
    color: $color-charcoal;
    display: block;
    font-size: 22px;
    font-weight: 700;
    line-height: 27.68px;
    margin-bottom: 8px;
    margin-top: 0;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
    }

    @include screen-min-width($medium-standard) {
        line-height: 24px;
    }
}

.description {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}

.subscribe-wrapper {
    margin-top: 16px;
    max-width: 600px;
}

.subscribe-title {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 16.35px;
    margin-bottom: 8px;
}

.subscribe-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
