@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.translate-message {
    color: #666;
    font-family: $font-stack-sans-serif;
    font-size: 14px;
    font-weight: 100;
    line-height: 22.4px;

    @include screen-min-width($medium-standard) {
        width: 80%;
    }

    .bold {
        font-weight: 400;
    }

    &.es,
    &.pt {
        display: inline-block;
    }

    &.disclaimer {
        margin-top: 30px;
    }
}

.link {
    color: $color-marine;
    padding-bottom: 10px;
    padding-top: 30px;
    text-decoration: none;

    &.translate {
        display: none;
    }

    &.block {
        display: block;
    }
}
