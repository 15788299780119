@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    display: none;

    @include screen-min-width($medium-plus) {
        border-left: 1px solid #e2e2e2;
        display: block;
        float: right;
        margin-bottom: 50px;
        margin-left: 50px;
        margin-right: -55px;
        margin-top: 9px;
        max-width: 338px;
        padding-left: 24px;
        padding-top: 0;
    }

    @include screen-min-width($x-large-plus) {
        margin-left: 45px;
        margin-right: -60px;
    }
}
