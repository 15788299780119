@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.no-summary {
    &.standard {
        padding-bottom: 60px;

        @include screen-min-width($medium-standard) {
            padding-bottom: 76px;
        }
    }

    &.premium {
        padding-bottom: 28px;
    }
}

.img {
    margin: auto;
    width: 100%;
}

.container-caption-credits {
    font-family: $font-stack-sans-serif;
    line-height: 1.15;
    margin-top: 8px;

    &.big-idea {
        margin-left: 20px;
    }

    &.spotlight {
        margin-left: 20px;

        @include screen-min-width($medium-plus) {
            margin-left: 0;
        }
    }

    &.premium {
        margin-left: 20px;

        @include screen-min-width($medium-plus) {
            margin-left: 0;
        }
    }
}

.credits,
.caption {
    font-size: 10px;
    font-weight: 400;
}

.credits {
    color: #a0a0a0;
}

.caption {
    color: #505050;
}

.container-video {
    min-height: calc(100vw * 9 / 16);
}
