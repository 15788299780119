@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";

.subheader {
    font-family: $font-stack-sans-serif;
}

.h2 {
    color: $color-charcoal;
    font-size: 19px;
    margin: 0 0 12px;

    @include screen-min-width($medium-plus) {
        font-size: 21px;
    }
}

.h2.big-idea {
    line-height: 21px;
    margin-bottom: 4px;
    padding-bottom: 8px;
}

.h3 {
    color: $color-charcoal;
    font-size: 17px;
    line-height: 1.632;
    margin: 0 0 12px;

    @include screen-min-width($medium-plus) {
        font-size: 19px;
    }
}

.text-center {
    margin-bottom: 32px;
    text-align: center;
}

.h4 {
    color: $color-charcoal;
    font-size: 18px;
    margin: 0 0 12px;

    @include screen-min-width($medium-plus) {
        font-size: 23px;
    }
}

.h5 {
    color: $color-charcoal;
    font-size: 18px;
    margin: 0 0 12px;

    &.sidebar-carousel {
        color: $color-iron;
        font-size: 16px;

        @include screen-min-width($medium-standard) {
            font-size: 18px;
        }
    }
}

.h6 {
    color: $color-charcoal;
    font-size: 16px;
    line-height: 1;

    &.sidebar-carousel {
        color: $color-iron;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.14px;
        line-height: 20px;
        margin-bottom: 12px;

        @include screen-min-width($medium-standard) {
            font-size: 16px;
            letter-spacing: -0.19px;
            line-height: 24px !important;
        }
    }
}
