@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.carousel-container {
    background-color: $color-white;
    border: 1px solid $legacy-color-border-gray;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-grid;
    margin: 9px auto 48px;
    max-width: 350px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    width: 100%;

    @include screen-min-width($medium-standard) {
        max-width: 100%;
        padding-left: 32px;
        padding-right: 32px;
    }
}

.header {
    border-bottom: 1px solid $legacy-color-border-gray;
    margin-bottom: 14px;
    margin-top: 14px;
    padding-bottom: 12px;

    @include screen-min-width($medium-standard) {
        margin-top: 22px;
    }

    .slug {
        color: #000;
        font-family: $font-stack-sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .title {
        color: $color-charcoal;
        font-family: $font-stack-sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.143px;
    }
}

.carousel {
    display: flex;
    height: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}

.slide {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    position: relative;
    scroll-snap-align: start;
}

.controls {
    display: flex;
    height: 30px;
    justify-content: center;
    margin-bottom: 16px;
    position: relative;
    width: 100%;

    @include screen-min-width($medium-standard) {
        margin-bottom: 24px;
    }
}

.buttons {
    display: none;

    @include screen-min-width($medium-standard) {
        display: inline-flex;
        position: absolute;
        right: 10px;
    }
}

.button {
    background-color: transparent;
    border: 0;
    margin-left: 6px;

    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }

    &.disabled {
        opacity: 0.25;
        pointer-events: none;
    }
}

.icon {
    color: $color-pewter;
    height: 28px;
    width: 28px;
}

.indicators {
    align-items: center;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: center;
    list-style: none;
    margin: 0;
}

.indicator {
    background-color: #c3dddb;
    border-radius: 50%;
    height: 6px;
    margin: 0 6px 0 0;
    transition: background-color 0.2s ease;
    width: 6px;
}

.indicator-active {
    background-color: $color-iron;
}

.right-circle {
    fill: $color-pewter;
    height: 28px;
    margin-bottom: 5px;
    width: 28px;
}

.left-circle {
    fill: $color-pewter;
    height: 28px;
    margin-bottom: 5px;
    transform: rotate(180deg);
    width: 28px;
}
