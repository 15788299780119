@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.non-magazine-date-container {
    margin-bottom: 24px;
    margin-top: 12px;
    width: 100%;

    &.standard {
        margin-top: 16px;
    }

    &.big-idea {
        margin-bottom: 21px;
        text-align: center;
    }
}

.non-magazine-date {
    color: #a0a0a0;
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.25px;

    @include screen-min-width($medium-standard) {
        font-size: 14px;
    }
}

.magazine-date-container {
    margin-bottom: 24px;
    margin-top: 16px;

    @include screen-min-width($medium-standard) {
        font-size: 14px;
    }

    &.spotlight {
        text-align: center;
    }
}

.magazine-date {
    background: none;
    color: #a0a0a0;
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.25px;
}

.bottom-magazine-date-container {
    margin-bottom: 12px;
    margin-top: 12px;
}

.bottom-magazine-date-anchor {
    color: $color-marine;
}

.bottom-magazine-date {
    font-family: $font-stack-sans-serif;
    font-size: 15px;
    font-weight: 200;
    letter-spacing: -0.25px;
    line-height: 1;
}
