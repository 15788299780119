@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.ordered-list {
    font-size: 17px;
    line-height: 1.8;
    list-style-position: outside;
    margin: 0 0 32px 22.5px;

    &.big-idea,
    &.spotlight,
    &.premium {
        @include screen-min-width($medium-plus) {
            font-size: 18px;
        }
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            font-size: 18px;
        }
    }

    li {
        font-family: $font-stack-tiempos;
        margin-bottom: 11.2px;
    }
}

.sidebar-list {
    li {
        color: #666;
        font-family: $font-stack-sans-serif;
        font-size: 18px;
        font-weight: 200;
        line-height: 27px !important;
    }

    li div {
        line-height: 27px;
    }
}
