@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.article-gift {
    position: relative;
}

.gift-button {
    background-color: $color-white;
    border-style: none;
    color: inherit;
    font-family: $font-stack-sans-serif;
    font-size: 12px;
    font-weight: 200;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        opacity: 0.75;
    }
}

.gift-icon {
    background: rgba(7, 94, 100, 0.2);
    border-radius: 50%;
    content: url("../../assets/GiftArticleLarge.svg");
    margin-top: -8.5px;
    padding: 4.5px;

    &.top {
        content: url("../../assets/GiftArticleMedium.svg");
        padding: 4.5px;
    }
}

.utility-name {
    display: none;
    line-height: 10px;

    &.top {
        padding-top: 6px;
    }

    &.bottom {
        padding-top: 4px;
    }

    @include screen-min-width($x-large-standard) {
        margin-top: 8px;

        &.bottom {
            margin-top: 0;
        }
    }

    @include screen-min-width($medium-standard) {
        display: block;
    }

    &.premium {
        display: none;

        @include screen-min-width($medium-plus) {
            display: block;
        }
    }
}
