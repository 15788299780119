@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";

.container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.action {
    background-color: transparent;
    border-style: none;
    color: $color-charcoal;
    cursor: pointer;
    display: flex;
    font-family: $font-stack-tiempos;
    font-size: 18px;
    gap: 4px;

    &:hover {
        opacity: 0.6;
    }
}
