@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.modal-overlay {
    background-color: $color-white;
    height: 100vh;
    left: 0;
    opacity: 0.8;
    position: fixed;
    top: 0;
    width: 100vw;

    @include screen-min-width($medium-standard) {
        display: none;
    }
}

.gift-tooltip {
    background: $color-white;
    border: 0.5px solid $legacy-color-gray-lighter;
    border-radius: 2px;
    bottom: 0;
    box-shadow: rgba(40, 40, 40, 0.25) -1.5px 1.5px 5px;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: 1;

    @include screen-min-width($medium-standard) {
        height: 180px;
        position: absolute;
        width: 260px;

        &.left {
            left: 95px;
            top: -20px;
        }

        &.top,
        &.bottom {
            left: 0;
            top: 75px;
        }
    }
}

.close-button {
    background-color: transparent;
    border-style: none;
    position: absolute;
    right: 16px;
    top: 16px;
}

.close-icon {
    cursor: pointer;
    height: 12px;
}

.tooltip-arrow {
    display: none;

    @include screen-min-width($medium-standard) {
        display: block;
        position: absolute;

        &.left {
            left: -15px;
            top: 40px;
        }

        &.top,
        &.bottom {
            left: 17px;
            top: -15px;
            transform: rotate(90deg);
        }
    }
}

.tooltip-body {
    display: flex;
    flex-direction: column;
    font-family: $font-stack-sans-serif;
    margin: 32px 16px;

    @include screen-min-width($medium-standard) {
        margin: 24px 16px;
    }
}

.tooltip-header {
    align-items: flex-start;
    border-bottom: 0.5px solid $legacy-color-gray-lighter;
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.new-text {
    color: $color-marine;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 3px;
}

.tooltip-heading {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
}

.tooltip-content {
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 8px 0 0;
    text-align: left;
}

.copy-button {
    align-items: center;
    background-color: transparent;
    border-style: none;
    color: $color-charcoal;
    display: flex;
    font-family: $font-stack-sans-serif;
    margin-top: 10px;
    width: fit-content;
}

.clickable {
    cursor: pointer;
}

.link-icon {
    width: 16px;
}

.copy-link-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
}

.spinner {
    height: 16px;
    margin-top: 10px;
    width: 16px;
}
