@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

@mixin common-medium-up() {
    flex-direction: row;
    margin: 48px calc(-50px);
    padding-bottom: 32px;
    padding-top: 32px;
    width: calc(100% + 100px);

    .header-container {
        border-right: 1px solid #e2e2e2;
        flex: 1 0 112px;
        padding-right: 16px;
    }

    .content {
        flex-direction: row-reverse;
        padding-left: 0;

        .summary {
            padding: 0 16px;
        }

        .image-container {
            flex: 0 0 300px;
            padding: 0;
        }
    }
}

.container {
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    color: $color-charcoal;
    display: flex;
    flex-direction: column;
    font-family: "GT America";
    line-height: 22.4px;
    margin: 48px 0;
    padding: 24px 0;
    text-size-adjust: 100%;

    .link {
        color: inherit;
        text-decoration: none;
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            @include common-medium-up();
        }
    }

    &:not(.standard) {
        @include screen-min-width($medium-plus) {
            @include common-medium-up();
        }
    }
}

.header-container {
    font-size: 14px;
    font-style: normal;
    line-height: 1.11;

    .header {
        font-weight: 900;
    }

    .series-title {
        letter-spacing: -0.01px;
        line-height: 19px;
    }

    .subheader {
        color: $color-iron;
        font-weight: 100;
        margin: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;

    .article-title {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.17px;
        line-height: 22px;
        margin-bottom: 8px;

        @include screen-min-width($medium-standard) {
            font-size: 21.6px;
        }
    }

    .article-title:hover {
        opacity: 0.6;
    }

    .dek {
        font-family: "GT America";
        font-size: 14px;
        letter-spacing: 0.14px;
        line-height: 1.36;
        margin: 0;
        @include screen-min-width($medium-standard) {
            line-height: 19px;
        }
    }

    .dek:hover {
        opacity: 0.6;
    }

    .image-container .link {
        display: flex;
    }

    .thumbnail {
        height: auto;
        width: 100%;
    }

    .thumbnail:hover {
        opacity: 0.6;
    }
}

.content > .image-container {
    padding: 16px 0;

    @include screen-min-width($medium-plus) {
        padding: 24px 0 20px;
    }

    &.standard {
        @include screen-min-width($x-large-standard) {
            flex: 0 0 350px;
        }
    }

    &:not(.standard) {
        @include screen-min-width($x-large-plus) {
            flex: 0 0 350px;
        }
    }
}
