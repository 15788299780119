@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

.author-info-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    &:last-child {
        margin-bottom: 0;
    }

    @include screen-min-width($medium-plus) {
        flex-direction: row;
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            flex-direction: row;
        }
    }

    &.inline-bio {
        padding-bottom: 14px;

        @include screen-min-width($medium-plus) {
            flex-direction: column;
        }
    }
}

.image-container {
    background: #c3dddb;
    border-radius: 50%;
    height: 70px;
    margin-bottom: 16px;
    margin-right: 24px;
    width: 70px;
}

.author-picture {
    border-radius: 50%;
    display: block;
    font-family: $font-stack-sans-serif;
    font-size: 36px;
    line-height: 70px;
    mix-blend-mode: multiply;
    text-align: center;
    width: 70px;
}

.author-bio-container {
    display: block;
    width: 312px;
}

.author-name {
    color: $color-charcoal;
    display: inline;
    font-family: $font-stack-sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}

.biography {
    display: inline;
    font-family: $font-stack-tiempos;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.x-handle {
    margin-top: 16px;

    .x-link {
        color: $color-charcoal;
        text-decoration: none;
    }

    .x-link:hover {
        opacity: 0.6;
    }

    .x-text {
        font-family: $font-stack-sans-serif;
        font-size: 15px;
        margin-left: 12px;
    }
}
