@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.link {
    background-color: #fff;
    border: 2px solid $color-charcoal;
    bottom: 0;
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    font-size: 18px;
    line-height: 18px;
    margin: 10px;
    padding: 5px;
    position: fixed;
    right: 0;
    text-decoration: none;
    z-index: 1000000;

    &:hover {
        border-color: $color-hbr-red;
        color: $color-hbr-red;
    }
}
