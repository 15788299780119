@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.img-container {
    width: 100%;

    @include screen-min-width($x-large-plus) {
        display: flex;
        width: 50%;
    }

    .img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.container {
    display: flex;
    height: calc(100vh - 120px);
    justify-content: space-between;

    &.left {
        flex-direction: row-reverse;
    }

    &.right {
        flex-direction: row;
    }
}

.topic {
    color: $color-iron;
    font-size: 16px;
    font-weight: 700;

    .container.dark & {
        color: #d6d6d6;
    }
}

.title {
    color: $color-charcoal;
    font-family: $font-stack-tiempos-headline;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 0.39px;
    line-height: 59.2px;
    margin: 12.8px 0 6.4px;

    .container.dark & {
        color: #f5f6f8;
    }

    .container.right & {
        text-align: center;
    }
}

.dek {
    color: $color-pewter;
    font-family: $font-stack-sans-serif;
    font-size: 18px;
    font-weight: 200;

    .container.right & {
        text-align: center;
    }

    .container.dark & {
        color: #d6d6d6;
    }
}

.date {
    color: #a0a0a0;
    font-family: $font-stack-sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 15px;
    margin-top: 30px;

    .container.dark & {
        color: #f5f6f8;
    }
}

.author {
    color: $color-charcoal;
    font-size: 20px;
    font-weight: 400;
    text-decoration: none;

    .container.dark & {
        color: $color-white;
    }
}

.byline {
    color: $color-charcoal;

    .container.dark & {
        color: $color-white;
    }
}

.half-text-left {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 0 calc(0.05 * 100vw - 4.16px);
    width: 50%;

    @include screen-min-width($x-large-plus) {
        display: flex;
    }

    @include screen-min-width(1215px) {
        padding-left: calc(0.06 * 100vw - 11.39px);
    }

    @include screen-min-width($xx-large-plus) {
        padding-left: 61px;
    }

    @include screen-min-width(1600px) {
        padding-left: calc(0.25 * 100vw - 307.79px);
    }
}

.title-container {
    max-width: 600px;
}

.half-text-right {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 0 200px;
    width: 50%;

    @include screen-min-width($x-large-plus) {
        display: flex;
    }
}

.dark {
    background-color: $color-charcoal;
}

.light {
    background-color: $color-white;
}
