@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";

.section-title {
    font-family: $font-stack-lava;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.09;
    margin-top: 16px;
    padding: 0 50px;

    &.big-idea,
    &.spotlight,
    &.premium {
        @include screen-min-width($medium-plus) {
            font-size: 22px;
            padding: 0;
        }
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            font-size: 22px;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.ordinal-enclosure {
    color: $legacy-color-gray-light;
    font-family: $font-stack-lava;
    font-size: 16px;
    font-weight: 400;

    .ordinal {
        color: $color-pewter;
        font-family: $font-stack-sans-serif;
        font-weight: 900;
        letter-spacing: -0.5px;
    }

    &.big-idea,
    &.spotlight,
    &.premium {
        @include screen-min-width($medium-plus) {
            font-size: 20px;
        }
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            font-size: 20px;
        }
    }
}

.section-marker {
    margin-bottom: 32px;
    margin-top: 48px;
    text-align: center;
}

.section-header {
    display: inline;
}
