@import "~mfe-articles-shared/scss/variables/grid-layout";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/mixins/grid-layout";

.container {
    grid-template-areas:
        "#{$header-area}"
        "#{$hero-area}"
        "#{$top-utils-area}"
        "#{$summary-area}"
        "#{$content-area}"
        "#{$author-area}"
        "#{$bottom-utils-area}"
        "#{$bottom-related-topics-area}"
        "#{$recommended-area}";
    position: relative;

    @include standard-grid();

    @include screen-min-width($large-standard) {
        grid-template-areas:
            "#{$header-area} ."
            "#{$hero-area} #{$right-area}"
            "#{$top-utils-area} #{$right-area}"
            "#{$summary-area} #{$right-area}"
            "#{$content-area} #{$right-area}"
            "#{$author-area} ."
            "#{$bottom-utils-area} ."
            "#{$recommended-area} #{$recommended-area}";

        grid-template-rows: auto auto auto 1fr;

        max-width: $standard-max-width;
        padding: 0 40px;
    }

    @include screen-min-width($x-large-standard) {
        grid-template-areas:
            ". #{$header-area} ."
            ". #{$hero-area} #{$right-area}"
            ". #{$summary-area} #{$right-area}"
            "#{$left-area} #{$content-area} #{$right-area}"
            ". #{$author-area} ."
            ". #{$bottom-utils-area} ."
            ". #{$recommended-area} #{$recommended-area}";

        padding: 0;
    }
}

.top-container {
    display: unset;
    position: static;
}

.header-container {
    display: unset;
    order: 1;
    z-index: 10;

    @include screen-min-width($x-large-plus) {
        position: relative;
    }
}

.primary-ad-container {
    order: 0;
}

.article-header {
    grid-area: $header-area;
}

.top-utils {
    display: none;
    grid-area: $top-utils-area;

    @include screen-min-width(#{$medium-standard}) {
        display: block;
    }
}

.left-rail {
    grid-area: $left-area;
    position: absolute;
}

.right-rail {
    display: none;

    @include screen-min-width($large-standard) {
        display: block;
        grid-area: $right-area;
        padding-left: $right-rail-margin-standard;
    }

    @include screen-min-width(1267px) {
        padding-left: 128px;
    }
}

.right-rail-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @include screen-min-width(1054px) {
        padding-left: calc(100% - 300px);
    }

    @include screen-min-width($x-large-standard) {
        padding-left: 0;
    }

    @include screen-min-width(1181px) {
        margin-right: 40px;
        padding-left: calc(100% - 348px + 8px);
    }

    @include screen-min-width(1267px) {
        padding-left: 0;
    }
}

.right-rail-middle-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
}

.right-rail-related-topics {
    flex-grow: 0;
}

.recommend-right {
    margin-top: 0;
    min-height: 600px;
}

.content {
    grid-area: $content-area;
}

.hero-image {
    grid-area: $hero-area;
    margin-left: -20px;
    margin-right: -20px;

    @include screen-min-width(#{$medium-standard}) {
        margin-left: -10px;
        margin-right: -10px;
    }

    @include screen-min-width(#{$large-standard}) {
        margin-left: 0;
        margin-right: 0;
    }
}

.summary {
    grid-area: $summary-area;
}

.author {
    grid-area: $author-area;
}

.bottom-utils {
    grid-area: $bottom-utils-area;
}

.bottom-series-nav {
    grid-area: $bottom-series-nav-area;
    margin: 0 auto;
}

.bottom-related-topics {
    display: block;
    grid-area: $bottom-related-topics-area;
    margin: 0 auto;

    @include screen-min-width($large-standard) {
        display: none;
    }
}

.recommended {
    grid-area: $recommended-area;

    @include screen-min-width(580px) {
        margin: 0 calc(-1 * (0.5 * 100vw - 290px));
    }

    @include screen-min-width($medium-standard) {
        margin: 0 calc(-1 * (0.5 * 100vw - 352px));
    }

    @include screen-min-width($large-standard) {
        margin-left: calc(917px - 100vw);
        margin-right: 0;
    }

    @include screen-min-width($x-large-standard) {
        margin-left: calc(1010px - 100vw);
    }

    @include screen-min-width(1273px) {
        margin-left: -258px;
    }
}
