@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";

.anchor,
.no-border-link {
    color: inherit;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
    }
}

.anchor {
    box-shadow: inset 0 -3px 0 #c3dddb;

    &.sidebar-link {
        color: $color-charcoal;
    }
}

.no-border-link,
.chart-link,
.related-video {
    box-shadow: none;
}

.no-border-link {
    color: $color-charcoal;
    line-height: 22.4px;
}

.chart-link {
    &:hover {
        opacity: 1;
    }
}

.related-video {
    display: block;
    font-size: 17px;
    margin-bottom: 25px;
}

.related-video > small {
    display: block;
    float: right !important;
}

.promo-link {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    font-size: 17px;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
    }
}
