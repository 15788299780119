@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/grid-layout";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    margin: 0 auto;
    max-width: 900px;
    padding-bottom: 48px;
    width: 100%;

    @include screen-min-width($article-width) {
        padding-top: 80px;
    }
}

.header {
    font-family: $font-stack-sans-serif;
    padding: 24px 16px;
    padding-left: 0;
    position: relative;

    @include screen-min-width($article-width) {
        padding: 0 0 10px;
        text-align: center;
    }

    .link,
    .link:hover {
        color: $color-charcoal;
        display: block;
        font-weight: 200;
        text-decoration: none;
        width: 100%;

        @include screen-min-width($article-width) {
            display: inline;
        }
    }
}

.title-divider {
    display: none;
    padding: 0 3px;

    @include screen-min-width($article-width) {
        display: inline;
    }
}

.title {
    color: $color-iron;
    display: block;
    font-weight: 700;
    letter-spacing: -0.16px;

    @include screen-min-width($article-width) {
        display: inline;
    }
}

.content {
    border-top: 1px #e2e2e2 solid;
    display: grid;
    grid-template-columns: repeat(1fr);

    @include screen-min-width($article-width) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0;
    }
}
