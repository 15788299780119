@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.list-item {
    flex: 0 0 30%;
    line-height: 17.8px;
    list-style-type: none;
    margin-bottom: 18px;
    min-width: 80px;
}

.item-link {
    color: $color-charcoal;
    display: flex;
    font-family: $font-stack-sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.58px;
    position: relative;
    text-decoration: none;

    @include screen-min-width($medium-standard) {
        font-size: 13px;
        line-height: 16px;
    }
}

.podcast-icon {
    height: 20px;
    margin-right: 4px;
    padding: 2px;
    top: 0;
    width: 20px;

    @include screen-min-width($medium-standard) {
        height: 24px;
        width: 24px;
    }

    &.apple-icon {
        width: 30.25%;

        @include screen-min-width(395px) {
            max-width: 20px;
        }

        @include screen-min-width($medium-standard) {
            max-width: 24px;
        }
    }
}

.podcast-media {
    align-content: center;
    display: inline;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
