@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

.editor-note {
    color: #737373;
    font-family: $font-stack-sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 1;

    @include screen-min-width($medium-plus) {
        font-size: 23px;
    }
}

.editor-note strong {
    font-weight: 900;
}

.editor-note a {
    color: $color-charcoal;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        opacity: 0.6;
    }
}
