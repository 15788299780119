@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    max-width: 100%;

    &:not(.standard) {
        @include screen-min-width($x-large-plus) {
            align-self: flex-end;
        }
    }
}

.related-topics {
    color: $color-charcoal;
    display: block;
    font-family: $font-stack-sans-serif;
    font-size: 15px;
    line-height: 1.6;
    margin: 48px 0;
    max-width: 100%;

    &:not(.standard) {
        @include screen-min-width($x-large-plus) {
            border-left: 1px solid #e2e2e2;
            margin: 48px 0 32px;
            margin-left: 32px;
            margin-right: 42px;
            max-width: 250px;
            padding-left: 16px;
        }

        @include screen-min-width(1408px) {
            margin-right: 20px;
        }

        @include screen-min-width(1472px) {
            margin-right: 0;
        }
    }

    &.standard {
        @include screen-min-width($large-standard) {
            border-left: 1px solid #e2e2e2;
            margin: 48px 0 32px;
            max-width: 250px;
            padding-left: 16px;
        }
    }
}

.topic-link {
    color: $color-iron;
    text-decoration: none;
}

.topic-link:hover {
    opacity: 0.6;
}
