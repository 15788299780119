@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    @media print {
        display: none;
    }
}

.container > iframe {
    height: 100%;
    padding: 16px;
    padding-bottom: 44px;
    width: calc(100% - (40px * 2));
}

.paywall,
.not-visible {
    display: none;
}
