@import "~mfe-articles-shared/scss/variables/colors";

.paywall {
    background-color: $color-white;
    min-height: 300px;
    position: relative;

    @media print {
        display: none;
    }
}

.hidden {
    display: none;
}
