@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";

.callout {
    color: $color-charcoal;
    font-family: $font-stack-sans-serif;
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
    padding: 24px 0 48px;
}
