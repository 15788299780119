@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/grid-layout";

$magazine-icon-sizes: (
    "w25": 25%,
    "w30": 30%,
    "w35": 35%,
    "w40": 40%,
    "w45": 45%,
    "w50": 50%,
);

@mixin breakout-widths($pct) {
    $vw: $pct * 1vw;
    height: auto;
    margin: 0;
    max-width: none;
    width: 100%;

    @include screen-min-width(565px) {
        margin-left: calc(-1 * (#{$vw} - #{$article-width-sm}) / 2);
        width: $vw;
    }

    @include screen-min-width($x-large-plus) {
        margin-left: calc(-1 * (#{$vw} - #{$article-width}) / 2);
        width: $vw;
    }
}

@mixin generateIconWithSizing($size: 20%) {
    float: left;
    height: auto;
    margin-right: 20px;
    margin-top: 5px;
    width: $size;
}

/* Styles for all images inside content */
.img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

/* breakout rules */
.is-inside-figure {
    &.breakout-full {
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: none;
        position: relative;
        right: 50%;
        width: 100vw;
    }

    &.breakout-80 {
        @include breakout-widths(80);
    }

    &.breakout-70 {
        @include breakout-widths(70);
    }

    &.breakout-60 {
        @include breakout-widths(60);
    }
}

.image-highlight {
    @include generateIconWithSizing;

    @each $icon-size, $size in $magazine-icon-sizes {
        &-#{$icon-size} {
            @include generateIconWithSizing($size);
        }
    }
}

.sidebar-image {
    display: block;
    height: auto;
    margin: 0 16px 16px 0 !important;
    padding: 0;
    width: 100%;
}
