@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

.newsletter {
    border-bottom: 1px $legacy-color-border-gray solid;
    border-top: 1px #e2e2e2 solid;
    font-family: "GT America";
    margin-bottom: 24px;
    margin-top: 24px;
    padding-bottom: 24px;
    padding-top: 24px;

    @include screen-min-width($medium-plus) {
        margin-bottom: 48px;
        margin-top: 48px;
        padding-bottom: 32px;
        padding-top: 32px;
    }

    @media print {
        display: none;
    }
}

.container {
    display: flex;
}

.icon-wrapper {
    margin-right: 24px;
}

.icon {
    height: 40px;
    width: 40px;

    @include screen-min-width($medium-standard) {
        height: 50.5px;
        margin-top: -5px;
        width: 50.5px;
    }
}

.content {
    @include screen-min-width($medium-plus) {
        align-items: flex-end;
        display: flex;
    }
}

.content-wrapper {
    margin-right: 75px;
}

.headline {
    color: #505050;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: -0.5px;
    line-height: 1;
    margin-bottom: 0;
}

.name {
    color: $color-charcoal;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.6875px;
    margin-bottom: 11px;
}

.description {
    color: $color-charcoal;
    font-size: 14px;
    line-height: 19px;
}

.button-wrapper {
    @include screen-min-width($medium-plus) {
        display: flex;
        flex-shrink: 0;
    }
}

.button {
    border: 0.5px solid #000;
    border-radius: 2px;
    font-size: 14px;
    height: 34px;
    line-height: 1;
    margin-top: 24px;
    padding: 9px 24px;
}

.subscribed-container {
    font-family: "GT America";
    margin-bottom: 32px;
    margin-top: 32px;
}

.subscribed-headline {
    color: #505050;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: -0.5px;
    margin-bottom: 14.5px;
}

.subscribed-notice {
    font-size: 14px;
    line-height: 1.357;
}

.notice-anchor {
    border-bottom: 3px solid #c3dddb;
}
