@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.unordered-list {
    font-size: 17px;
    line-height: 1.8;
    list-style-position: outside;
    margin: 0 0 32px 17.6px;

    &.big-idea,
    &.spotlight,
    &.premium {
        @include screen-min-width($medium-plus) {
            font-size: 18px;
        }
    }

    &.standard {
        @include screen-min-width($medium-standard) {
            font-size: 18px;
        }
    }

    li {
        font-family: $font-stack-tiempos;
        margin-bottom: 11.2px;
    }

    em {
        font-style: italic;
        line-height: inherit;
    }
}

.table-list {
    padding: 9px 11px;

    li {
        color: #222;
        font-family: $font-stack-sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
    }
}

.sidebar-list {
    li {
        color: #666;
        font-family: $font-stack-sans-serif;
        font-size: 18px;
        font-weight: 200;
        line-height: 27px !important;
        margin: 12px 0;
    }

    li div {
        line-height: 27px;
    }
}

.sidebar-carousel-list {
    li {
        color: $color-iron;
        font-family: $font-stack-sans-serif;
        font-size: 14px;
        font-weight: 200;
        letter-spacing: -0.1px;
        line-height: 20px !important;
        margin-bottom: 8px;

        @include screen-min-width($medium-standard) {
            font-size: 16px;
            line-height: 25px !important;
            margin-bottom: 12px;
        }
    }
}

.sidebar-full {
    margin-left: -22px;
}
