@import "~mfe-articles-shared/scss/variables/grid-layout";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/mixins/grid-layout";

.container {
    grid-template-areas:
        "#{$header-area}"
        "#{$hero-area}"
        "#{$top-series-nav-area}"
        "#{$summary-area}"
        "#{$left-area}"
        "#{$content-area}"
        "#{$right-area}"
        "#{$author-area}"
        "#{$bottom-utils-area}"
        "#{$bottom-series-nav-area}"
        "#{$bottom-related-topics-area}";
    position: relative;

    @include plus-grid();

    @include screen-min-width($medium-plus) {
        grid-template-areas:
            "#{$header-area} #{$header-area} #{$header-area}"
            "#{$hero-area} #{$hero-area} #{$hero-area}"
            "#{$top-series-nav-area} #{$top-series-nav-area} #{$top-series-nav-area}"
            ". #{$summary-area} ."
            "#{$left-area} #{$content-area} #{$right-area}"
            ". #{$author-area} ."
            ". #{$bottom-utils-area} ."
            "#{$bottom-series-nav-area} #{$bottom-series-nav-area} #{$bottom-series-nav-area}"
            ". #{$bottom-related-topics-area} .";
    }
}

.top-container {
    display: unset;
    position: static;
}

.header-container {
    display: unset;
    order: 1;
    z-index: 10;

    @include screen-min-width($x-large-plus) {
        position: relative;
    }
}

.primary-ad-container {
    order: 0;
}

.article-header {
    font-size: 18px;
    grid-area: $header-area;
    margin: 0 auto;
    text-align: center;
}

.left-rail {
    grid-area: $left-area;
    line-height: 1;
    position: absolute;

    @include screen-min-width($x-large-plus) {
        left: 50%;
        padding-right: 3px;
        transform: translateX(-50%);
    }

    @include screen-min-width($xx-large-plus) {
        padding-right: 40px;
    }
}

.right-rail {
    display: none;

    @include screen-min-width($large-plus) {
        align-items: flex-start;
        display: flex;
        grid-area: $right-area;
        justify-content: center;
    }
}

.hero-image {
    grid-area: $hero-area;
    margin: 0 -20px;

    @include screen-min-width(580px) {
        margin: 0 calc(($article-width-sm + 15px - 100vw) / 2);
    }

    @include screen-min-width($medium-plus) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @include screen-min-width($x-large-plus) {
        margin: auto;
    }

    @include screen-min-width(1690px) {
        margin: 0 -20px;
    }
}

.content {
    grid-area: $content-area;
}

.summary {
    grid-area: $summary-area;
}

.author {
    grid-area: $author-area;
}

.bottom-utils {
    grid-area: $bottom-utils-area;
}

.bottom-series-nav {
    grid-area: $bottom-series-nav-area;
    margin: 0 auto;
}

.bottom-related-topics {
    display: block;
    grid-area: $bottom-related-topics-area;
    margin: 0 auto;

    @include screen-min-width($x-large-plus) {
        display: none;
    }
}
