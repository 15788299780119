@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.content-container {
    display: none;

    @include screen-min-width($large-plus) {
        display: none;
        font-family: $font-stack-lava;
        font-size: 16px;
        letter-spacing: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        &.visible {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}

.headline-title {
    font-family: $font-stack-lava;
}

.headline-separator {
    margin-left: 6px;
    margin-right: 6px;
}

.headline-series {
    color: #fe5502;
    font-weight: 600;
    letter-spacing: normal;
}
