@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    display: flex;
    padding-bottom: 16px;

    &.standard {
        padding-top: 32px;

        @include screen-min-width($medium-standard) {
            padding-top: 34px;
        }
        @include screen-min-width($x-large-plus) {
            padding-top: 72px;
        }
    }

    &.big-idea {
        display: none;
        justify-content: center;
        letter-spacing: -0.17px;
        padding-top: 32px;

        @include screen-min-width($medium-plus) {
            display: flex;
            letter-spacing: normal;
            padding-top: 36px;
        }
    }

    &.spotlight {
        justify-content: center;
        padding-top: 32px;

        @include screen-min-width($medium-standard) {
            padding-top: 34px;
        }
        @include screen-min-width($x-large-plus) {
            padding-top: 72px;
        }
    }

    &.premium {
        justify-content: center;
        letter-spacing: -0.17px;
        padding-top: 32px;

        @include screen-min-width($medium-standard) {
            letter-spacing: normal;
            padding-top: 34px;
        }
        @include screen-min-width($x-large-plus) {
            padding-top: 72px;
        }
    }
}

.topic {
    color: #505050;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.128px;
    text-transform: capitalize;

    &:hover {
        color: $color-charcoal;
    }

    @include screen-min-width($medium-standard) {
        font-size: 16px;
    }
}
