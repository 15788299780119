@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/grid-layout";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.container {
    padding-bottom: 48px;
    padding-top: 15px;

    @include screen-min-width($article-width) {
        margin: 0 -54px;
        width: calc(100vw - 54px);
    }

    @include screen-min-width(668px) {
        margin: 0 calc(($article-width - 100vw) / 2);
        width: $article-width;
    }

    @include screen-min-width($medium-plus) {
        margin: 0 auto;
        max-width: 1067.5px;
        width: 84vw;
    }
}

.header {
    font-family: $font-stack-sans-serif;
    padding: 24px 16px;
    padding-left: 0;
    position: relative;

    @include screen-min-width($article-width) {
        padding: 0 0 10px;
        text-align: center;
    }
}

.link,
.link:hover {
    color: $color-charcoal;
    display: block;
    font-weight: 200;
    text-decoration: none;
    width: 100%;

    @include screen-min-width($article-width) {
        display: inline;
    }
}

.title-divider {
    display: none;

    @include screen-min-width($article-width) {
        display: inline;
    }
}

.title {
    color: $color-iron;
    display: block;
    font-weight: 700;
    letter-spacing: -0.16px;

    @include screen-min-width($article-width) {
        display: inline;
    }
}

.content {
    border-top: 1px #e2e2e2 solid;
    display: flex;
    mask-image: linear-gradient(
        90deg,
        rgba(0, 212, 255, 0) 0%,
        #00d4ff 10%,
        #00d4ff 90%,
        rgba(0, 212, 255, 0) 100%
    );
    overflow: hidden;
    overflow-x: auto;
    overflow-y: none;
    scroll-snap-type: x mandatory;
}
