@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.text {
    color: $color-charcoal;
    font-family: $font-stack-tiempos;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.006px;
    line-height: 28px;
    margin-bottom: 32px;

    @include screen-min-width($medium-standard) {
        font-size: 18px;
        line-height: 31px;
    }

    object,
    embed {
        width: 100%;
    }
}

.callout {
    font-family: $font-stack-tiempos;
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    margin-bottom: 0;
}

.table-text {
    color: #000;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 27px;
    margin-bottom: 6px;
    padding: 7.4px 0;
}

.table-footer-text {
    font-family: inherit;
    font-size: inherit;
    font-weight: 200;
    height: fit-content;
    line-height: 13px;
    margin-bottom: 0;
    width: 80%;
}

.data-cell-text {
    color: #222;
    letter-spacing: -0.006px;
    line-height: 18px;
    margin-bottom: 0;
    padding: 9px 10px;

    &:first-child {
        border-right: 1px solid $legacy-color-gray-light;
        display: block;
        font-weight: 700;

        @include screen-min-width($medium-standard) {
            display: none;
        }
    }
}

.sidebar-text {
    color: $color-iron;
    font-family: $font-stack-sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 27px !important;
    margin-bottom: 0 !important;
    padding-bottom: 12px;
}

.sidebar-carousel-text {
    color: $color-iron;
    font-family: $font-stack-sans-serif;
    font-size: 14px;
    font-weight: 200;
    letter-spacing: -0.1px;
    line-height: 20px;
    margin-bottom: 12px;

    @include screen-min-width($medium-standard) {
        font-size: 16px;
        line-height: 25px;
    }
}

$magazine-icons-sizes: (
    "w25": 25%,
    "w30": 30%,
    "w35": 35%,
    "w40": 40%,
    "w45": 45%,
    "w50": 50%,
);

$magazine-icons-path: "~mfe-articles-shared/assets/mag-icons";

$magazine-icons: (
    "analytics-data-science":
        url("#{$magazine-icons-path}/HBR_MAG_ICON_AnalyticsAndDataScience.png"),
    "business-ethics":
        url("#{$magazine-icons-path}/HBR_MAG_ICON_BusinessEthics.png"),
    "innovation": url("#{$magazine-icons-path}/HBR_MAG_ICON_Innovation.png"),
    "marketing": url("#{$magazine-icons-path}/HBR_MAG_ICON_Marketing_NEW.png"),
    "politics": url("#{$magazine-icons-path}/HBR_MAG_ICON_Politics.png"),
    "diversity-inclusion":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_DiversityAndInclusion.png"),
    "employee-performance-management":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_EmployeePerformanceManagement.png"),
    "organizational-culture":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_OrganizationalCulture.png"),
    "sales": url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_Sales.png"),
    "strategy":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_Strategy.png"),
    "sustainable-business-practices":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_SustainableBusinessPractices.png"),
    "teams": url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_Teams.png"),
    "work-life-balance":
        url("#{$magazine-icons-path}/sept-oct/HBR_MAG_ICON_WorkLifeBalance.png"),
    "ai-machine-learning":
        url("#{$magazine-icons-path}/nov-dec/HBR_MAG_ICON_AIAndMachineLearning.png"),
    "leadership":
        url("#{$magazine-icons-path}/nov-dec/HBR_MAG_ICON_Leadership.png"),
    "managing-people":
        url("#{$magazine-icons-path}/nov-dec/HBR_MAG_ICON_ManagingPeople.png"),
    "mentoring":
        url("#{$magazine-icons-path}/nov-dec/HBR_MAG_ICON_Mentoring.png"),
    "business-society":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_BusinessAndSociety.png"),
    "entrepreneurship":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_Entrepreneurship.png"),
    "health-wellness":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_HealthAndWellness.png"),
    "leadership-styles":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_LeadershipStyles.png"),
    "organizational-decision-making":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_OrganizationalDecisionMaking.png"),
    "pricing-strategy":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_PricingStrategy.png"),
    "project-management":
        url("#{$magazine-icons-path}/jan-feb/HBR_MAG_ICON_ProjectManagement.png"),
    "hiring-recruitment":
        url("#{$magazine-icons-path}/mar-apr-2023/HBR_MAG_ICON_HiringAndRecruitment.png"),
    "hybrid-work":
        url("#{$magazine-icons-path}/mar-apr-2023/HBR_MAG_ICON_HybridWork.png"),
    "leading-teams":
        url("#{$magazine-icons-path}/mar-apr-2023/HBR_MAG_ICON_LeadingTeams.png"),
    "management":
        url("#{$magazine-icons-path}/mar-apr-2023/HBR_MAG_ICON_Management.png"),
    "time-management":
        url("#{$magazine-icons-path}/mar-apr-2023/HBR_MAG_ICON_TimeManagement.png"),
    "brand-management":
        url("#{$magazine-icons-path}/may-june-2023/HBR_MAG_ICON_BrandManagement.png"),
    "nonprofit-organizations":
        url("#{$magazine-icons-path}/may-june-2023/HBR_MAG_ICON_NonprofitOrganizations.png"),
    "talent-management":
        url("#{$magazine-icons-path}/may-june-2023/HBR_MAG_ICON_TalentManagement.png"),
    "climate-change":
        url("#{$magazine-icons-path}/jul-aug-2023/HBR_MAG_ICON_ClimateChange.png"),
    "cybersecurity-digital-privacy":
        url("#{$magazine-icons-path}/jul-aug-2023/HBR_MAG_ICON_CybersecurityDigitalPrivacy.png"),
    "labor": url("#{$magazine-icons-path}/jul-aug-2023/HBR_MAG_ICON_Labor.png"),
    "technology-analytics":
        url("#{$magazine-icons-path}/jul-aug-2023/HBR_MAG_ICON_TechnologyAnalytics.png"),
    "corporate-governance":
        url("#{$magazine-icons-path}/sept-oct-2023/HBR_MAG_ICON_CorporateGovernance.png"),
    "feedback":
        url("#{$magazine-icons-path}/sept-oct-2023/HBR_MAG_ICON_Feedback.png"),
    "national-competitiveness":
        url("#{$magazine-icons-path}/sept-oct-2023/HBR_MAG_ICON_NationalCompetitiveness.png"),
    "organizational-change":
        url("#{$magazine-icons-path}/sept-oct-2023/HBR_MAG_ICON_OrganizationalChange.png"),
    "business-models":
        url("#{$magazine-icons-path}/nov-dec-2023/HBR_MAG_ICON_BusinessModels.png"),
    "change-management":
        url("#{$magazine-icons-path}/nov-dec-2023/HBR_MAG_ICON_ChangeManagement.png"),
    "environmental-sustainability":
        url("#{$magazine-icons-path}/nov-dec-2023/HBR_MAG_ICON_EnvironmentalSustainability.png"),
    "private-equity":
        url("#{$magazine-icons-path}/nov-dec-2023/HBR_MAG_ICON_PrivateEquity.png"),
    "advertising":
        url("#{$magazine-icons-path}/2024/jan-feb/HBR_MAG_ICON_Advertising.png"),
    "decision-making-problem-solving":
        url("#{$magazine-icons-path}/2024/jan-feb/HBR_MAG_ICON_DecisionMakingAndProblemSolving.png"),
    "career-transitions":
        url("#{$magazine-icons-path}/2024/mar-apr/HBR_MAG_ICON_CareerTransitions.png"),
    "health-care-treatment":
        url("#{$magazine-icons-path}/2024/mar-apr/HBR_MAG_ICON_HealthCareTreatment.png"),
    "management-communication":
        url("#{$magazine-icons-path}/2024/mar-apr/HBR_MAG_ICON_ManagementCommunication.png"),
    "supply-chain-management":
        url("#{$magazine-icons-path}/2024/mar-apr/HBR_MAG_ICON_SupplyChainManagement.png"),
    "human-resource-management":
        url("#{$magazine-icons-path}/2024/may-jun/HBR_MAG_ICON_HumanResourceManagement.png"),
    "interpersonal-skills":
        url("#{$magazine-icons-path}/2024/may-jun/HBR_MAG_ICON_InterpersonalSkills.png"),
    "operational-transformation":
        url("#{$magazine-icons-path}/2024/may-jun/HBR_MAG_ICON_OperationalTransformation.png"),
    "organizational-transformation":
        url("#{$magazine-icons-path}/2024/may-jun/HBR_MAG_ICON_OrganizationalTransformation.png"),
    "career-accounting":
        url("#{$magazine-icons-path}/2024/jul-aug/HBR_MAG_ICON_CareerAccounting.png"),
    "customer-experience":
        url("#{$magazine-icons-path}/2024/jul-aug/HBR_MAG_ICON_CustomerExperience.png"),
    "economic-cycles-and-trends":
        url("#{$magazine-icons-path}/2024/jul-aug/HBR_MAG_ICON_EconomicCyclesAndTrends.png"),
    "managing-employees":
        url("#{$magazine-icons-path}/2024/jul-aug/HBR_MAG_ICON_ManagingEmployees.png"),
    "boards":
        url("#{$magazine-icons-path}/2024/sep-oct/HBR_MAG_ICON_Boards.png"),
    "competitive-strategy":
        url("#{$magazine-icons-path}/2024/sep-oct/HBR_MAG_ICON_CompetitiveStrategy.png"),
    "corporate-strategy":
        url("#{$magazine-icons-path}/2024/sep-oct/HBR_MAG_ICON_CorporateStrategy.png"),
    "workplace-health-and-safety":
        url("#{$magazine-icons-path}/2024/sep-oct/HBR_MAG_ICON_WorkplaceHealthAndSafety.png"),
    "design-thinking":
        url("#{$magazine-icons-path}/2024/nov-dec/HBR_MAG_ICON_DesignThinking.png"),
    "sales-and-marketing":
        url("#{$magazine-icons-path}/2024/nov-dec/HBR_MAG_ICON_SalesAndMarketing.png"),
    "succession-planning":
        url("#{$magazine-icons-path}/2024/nov-dec/HBR_MAG_ICON_SuccessionPlanning.png"),
    "work-environments":
        url("#{$magazine-icons-path}/2024/nov-dec/HBR_MAG_ICON_WorkEnvironments.png"),
    "experimentation":
        url("#{$magazine-icons-path}/2025/jan-feb/HBR_MAG_ICON_Experimentation.png"),
    "negotiation-strategies":
        url("#{$magazine-icons-path}/2025/jan-feb/HBR_MAG_ICON_NegotiationStrategies.png"),
    "organizational-learning":
        url("#{$magazine-icons-path}/2025/jan-feb/HBR_MAG_ICON_OrganizationalLearning.png"),
    "management-philosophy":
        url("#{$magazine-icons-path}/2025/mar-apr/HBR_MAG_ICON_ManagementPhilosophy.png"),
    "gender":
        url("#{$magazine-icons-path}/2025/mar-apr/HBR_MAG_ICON_Gender.png"),
    "managing-yourself":
        url("#{$magazine-icons-path}/2025/mar-apr/HBR_MAG_ICON_ManagingYourself.png"),
    "cross-cultural-management":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_CrossCulturalManagement.png"),
    "entrepreneurs-and-founders":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_EntrepreneursAndFounders.png"),
    "growth-strategy":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_GrowthStrategy.png"),
    "leadership-and-managing-people":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_LeadershipAndManagingPeople.png"),
    "product-development":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_ProductDevelopment.png"),
    "sustainability":
        url("#{$magazine-icons-path}/2025/may-june/HBR_MAG_ICON_Sustainability.png"),
);

@mixin generateBackgroundIconWithSizing($icon, $size: 20%) {
    &::before {
        background-image: $icon;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        float: left;
        height: 0;
        margin-right: 20px;
        margin-top: 5px;
        padding-top: $size;
        width: $size;
    }
}

.mag-icon {
    @each $magazineIcon, $icon in $magazine-icons {
        &-#{$magazineIcon} {
            @include generateBackgroundIconWithSizing($icon);

            @each $magazineIconSize, $size in $magazine-icons-sizes {
                &-#{$magazineIconSize} {
                    @include generateBackgroundIconWithSizing($icon, $size);
                }
            }
        }
    }
}

.hbr-100 {
    &::before {
        background-image: url("~mfe-articles-shared/assets/hbr-100.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        float: left;
        height: 85px;
        margin: 5px 20px 0 0;
        width: 85px;

        @include screen-min-width($medium-plus) {
            height: 152px;
            width: 152px;
        }
    }
}
