/* styelint-disable max-nesting-depth */
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.table {
    background: $color-white;
    border: solid 1px #ddd;

    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
    table-layout: auto;

    td,
    th {
        color: #222;
        display: table-cell;
        font-size: 14px;
        line-height: 18px;
        padding: 9px 10px;
        text-align: left;
    }
}

.grid-table {
    font-family: $font-stack-sans-serif;

    thead {
        display: none;
        @include screen-min-width($medium-standard) {
            display: table-header-group;
        }
    }

    thead th:not(:last-child) {
        border-right: 1px solid $legacy-color-gray-light;
    }

    tr {
        border-bottom: 0;
        display: grid;
        grid-template-columns: 1fr;

        @include screen-min-width($medium-standard) {
            border-bottom: 1px solid $legacy-color-gray-light;
            display: table-row;
        }
    }

    tr:nth-child(2n) {
        background-color: #f5f6f8;

        @include screen-min-width($medium-standard) {
            background-color: $color-white;
        }
    }

    tr:not(:last-child) td:last-child {
        border-bottom: 1px solid #222;

        @include screen-min-width($medium-standard) {
            border-bottom: 1px solid $legacy-color-gray-light;
        }
    }

    td,
    th {
        background-color: $color-white;
        padding: 0;
    }

    td {
        background-color: transparent;
        display: grid;
        grid-template-columns: 0.7fr 1.3fr;
        height: 100%;
        vertical-align: initial;

        @include screen-min-width($medium-standard) {
            display: table-cell;
        }
    }

    td:not(:last-child) {
        border-bottom: 1px solid $legacy-color-gray-light;
        border-right: 0;

        @include screen-min-width($medium-standard) {
            border-right: 1px solid $legacy-color-gray-light;
        }
    }

    th {
        color: #222;
        font-family: $font-stack-sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
        padding: 9px 10px;
    }
}

.sidebar-table {
    margin-bottom: 0;

    tr:nth-of-type(2n) {
        background: #f9f9f9;
    }
}
