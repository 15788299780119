@import "~mfe-articles-shared/scss/variables/colors";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/mixins/media-queries";

.dek {
    color: $color-pewter;
    display: none;
    font-family: $font-stack-sans-serif;
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: -0.3px;
    text-align: center;

    &:not(.big-idea) {
        font-size: 20px;
    }

    @include screen-min-width(#{$medium-plus}) {
        display: inline;
    }
}
