@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/grid-layout";

.table-container {
    font-family: $font-stack-sans-serif;
    max-width: $article-width-sm;

    @include screen-min-width($medium-standard) {
        max-width: $article-width;
    }
}
