@import "~mfe-articles-shared/scss/variables/fonts";
@import "~mfe-articles-shared/scss/mixins/media-queries";
@import "~mfe-articles-shared/scss/variables/media-queries";
@import "~mfe-articles-shared/scss/variables/colors";

.container {
    margin: 32px 0;

    * {
        color: #505050;
        font-family: $font-stack-sans-serif !important;
        font-size: 14px !important;
        letter-spacing: 0;
        line-height: 1.75 !important;
    }

    @include screen-min-width($medium-standard) {
        margin: 48px 0;
    }

    &.standard {
        margin: 48px 0;

        @include screen-min-width($medium-standard) {
            margin-top: 0;
        }

        @include screen-min-width($x-large-standard) {
            margin-top: 48px;
        }
    }
}

.initial-layout {
    max-height: 78px;
    overflow: hidden;
}

.final-layout {
    max-height: none;
    overflow: auto;
}

.summary-header {
    color: $color-charcoal;
    float: left;
    margin: 0;
}

.summary-text {
    margin-bottom: 16px;

    * {
        font-weight: 200 !important;
        letter-spacing: -0.016px;
        outline: none;
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        display: inline;
    }
}

.summary-title {
    font-weight: 700 !important;
    margin-bottom: 4.8px;
}

.button {
    background: none;
    border: 0;
    border-bottom: 3px #c3dddb solid;
    color: $color-charcoal;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 1 !important;
    margin-left: 10px;
}
