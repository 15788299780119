.video-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    z-index: 0;
    margin-bottom: 32px;

   > div:first-child {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.poster {
    width: 100%;
    height: auto;
    padding-bottom: 0;
    margin-bottom: 32px;
}